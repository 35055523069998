import React from 'react';
import GoogleMapReact from 'google-map-react';

const isClient = typeof window !== 'undefined';
const googleMapsApiKey = 'AIzaSyAcYBOyX1Ek0ip0U4G8ZPHefvacp2-utXM';

const mapStyle = [
  {
    "featureType": "all",
    "elementType": "geometry.fill",
    "stylers": [{ "weight": "2.00" }]
  },
  {
    "featureType": "all",
    "elementType": "geometry.stroke",
    "stylers": [{ "color": "#9c9c9c" }]
  },
  {
    "featureType": "all",
    "elementType": "labels.text",
    "stylers": [{ "visibility": "on" }]
  },
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [{ "color": "#f2f2f2" }]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry.fill",
      "stylers": [{ "color": "#ffffff" }]
  },
  {
      "featureType": "landscape.man_made",
      "elementType": "geometry.fill",
      "stylers": [{ "color": "#ffffff" }]
  },
  {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [{ "visibility": "off"}]
  },
  {
      "featureType": "road",
      "elementType": "all",
      "stylers": [{ "saturation": -100 }, { "lightness": 45 }]
  },
  {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [{ "color": "#eeeeee" }]
  },
  {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [{ "color": "#7b7b7b" }]
  },
  {
      "featureType": "road",
      "elementType": "labels.text.stroke",
      "stylers": [{ "color": "#ffffff" }]
  },
  {
      "featureType": "road.highway",
      "elementType": "all",
      "stylers": [{ "visibility": "simplified" }]
  },
  {
      "featureType": "road.arterial",
      "elementType": "labels.icon",
      "stylers": [{ "visibility": "off" }]
  },
  {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [{ "visibility": "off" }]
  },
  {
      "featureType": "water",
      "elementType": "all",
      "stylers": [{ "color": "#46bcec" }, { "visibility": "on" }]
  },
  {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [{ "color": "#c8d7d4" }]
  },
  {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [{ "color": "#070707" }]
  },
  {
      "featureType": "water",
      "elementType": "labels.text.stroke",
      "stylers": [{ "color": "#ffffff" }]
  }
];

export const GoogleMap = ({ address }) => {
  const lat = parseFloat(address.lat);
  const lng = parseFloat(address.lng);

  return (
    <div className="map">
      {isClient && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleMapsApiKey }}
          defaultCenter={[lat, lng]}
          defaultZoom={15}
          options={() => ({ styles: mapStyle })}
        >
          <div
            className="marker"
            lat={lat}
            lng={lng}
          />
        </GoogleMapReact>
      )}
    </div>
  );
}