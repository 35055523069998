import React from "react";
import { GoogleMap } from "../components/GoogleMap";
import Layout from "../components/Layout";
import Paper from "../components/Paper";

const Contacts = () => {
  const loc = { lat: 25.076899, lng: 55.147264 };

  return (
    <Layout fullgreen title="Contacts">
      <Paper>
        <div className="page-header">
          <h1>Contacts</h1>
          <p>We always happy to chit-chat with you</p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h6>Address</h6>
            <p className="address">
              INHB Supply Services
              <br />
              Astrolabs, Cluster R,
              <br />
              Jumeirah Lakes Towers, <br />
              Dubai, UAE,
              <br />
              P.O. Box 337097
            </p>
          </div>
          <div className="col-md-6">
            <h6>Email</h6>
            <p>
              <a href="mailto:hello@supplyme.ae">hello@supplyme.ae</a>
            </p>
            <h6 className="mt-4">Call</h6>
            <p>+971 58 516-81-16</p>
          </div>
        </div>
        <GoogleMap address={loc} />
      </Paper>
    </Layout>
  );
};

export default Contacts;
